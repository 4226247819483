import { AutoUnsubscriber } from '@library/classes/auto-unsubscriber/auto-unsubscriber';
import { QpBroadcasterLoggerService } from '@library/services/qp-logger/qp-broadcaster-logger.service';
import {
  EQpLoggerLevel,
  IQpLoggerConfig,
  EQpLoggerConsoleMethod,
  QpLoggerLevelType,
  QpBypassLoggerLevelType,
  BYPASS_LOGGER_LEVEL,
  QpLoggerMessagesType,
  IQpLoggerBroadcast,
} from '@library/services/qp-logger/qp-logger.service.models';
import { QP_LOGGER_CONFIG } from '@library/tokens/qp-logger-config.token';
import { Injectable, Inject, Optional } from '@angular/core';
import { lowerCase } from 'lodash/index';
import LogRocket from 'logrocket';
import { Subject, Observable, EMPTY } from 'rxjs';
import { flatMap, catchError } from 'rxjs/operators';

const LEVELS: { [key in EQpLoggerLevel | QpBypassLoggerLevelType]: (level: Readonly<QpLoggerLevelType>) => boolean } = {
  [EQpLoggerLevel.ERROR](level: QpLoggerLevelType): boolean {
    return level >= EQpLoggerLevel.ERROR;
  },
  [EQpLoggerLevel.WARNING](level: QpLoggerLevelType): boolean {
    return level >= EQpLoggerLevel.WARNING;
  },
  [EQpLoggerLevel.INFO](level: QpLoggerLevelType): boolean {
    return level >= EQpLoggerLevel.INFO;
  },
  [EQpLoggerLevel.DEBUG](level: QpLoggerLevelType): boolean {
    return level >= EQpLoggerLevel.DEBUG;
  },
  [EQpLoggerLevel.DISABLED](): false {
    return false;
  },
  [BYPASS_LOGGER_LEVEL](): true {
    return true;
  },
};

/**
 * @description
 * Exceptionally provided in root
 */
@Injectable({
  providedIn: 'root',
})
export class QpLoggerService extends AutoUnsubscriber {
  private static _getHumanizedLoggerLevel(loggerLevel: Readonly<EQpLoggerLevel>): string {
    return lowerCase(EQpLoggerLevel[loggerLevel]);
  }

  private _loggerLevel: EQpLoggerLevel = EQpLoggerLevel.DEBUG;
  private _httpLoggerLevel: EQpLoggerLevel = EQpLoggerLevel.DISABLED;
  private readonly _broadcast$: Subject<IQpLoggerBroadcast> = new Subject<IQpLoggerBroadcast>();

  public constructor(
    @Inject(QP_LOGGER_CONFIG) qpLoggerConfig: Readonly<IQpLoggerConfig>,
    @Optional() private readonly _qpBroadcasterLoggerService: QpBroadcasterLoggerService
  ) {
    super();

    this._loggerLevel = qpLoggerConfig.loggerLevel;
    this._httpLoggerLevel = qpLoggerConfig.httpLoggerLevel;

    this._init();
  }

  public get loggerLevel(): EQpLoggerLevel {
    return this._loggerLevel;
  }

  public set loggerLevel(level: EQpLoggerLevel) {
    this._loggerLevel = level;
  }

  public get httpLoggerLevel(): EQpLoggerLevel {
    return this._httpLoggerLevel;
  }

  public set httpLoggerLevel(level: EQpLoggerLevel) {
    this._httpLoggerLevel = level;
  }

  public debug(...messages: Readonly<QpLoggerMessagesType>): QpLoggerService {
    this._log(EQpLoggerLevel.DEBUG, EQpLoggerConsoleMethod.DEBUG, ...messages);

    return this;
  }

  public info(...messages: Readonly<QpLoggerMessagesType>): QpLoggerService {
    this._log(EQpLoggerLevel.INFO, EQpLoggerConsoleMethod.INFO, ...messages);

    return this;
  }

  public warn(...messages: Readonly<QpLoggerMessagesType>): QpLoggerService {
    this._log(EQpLoggerLevel.WARNING, EQpLoggerConsoleMethod.WARNING, ...messages);

    return this;
  }

  public error(...messages: Readonly<QpLoggerMessagesType>): QpLoggerService {
    this._log(EQpLoggerLevel.ERROR, EQpLoggerConsoleMethod.ERROR, ...messages);

    return this;
  }

  public group(label: Readonly<string>, isCollapsed: Readonly<boolean> = false): QpLoggerService {
    if (isCollapsed) {
      // eslint-disable-next-line no-restricted-globals
      console.groupCollapsed(label);
    } else {
      // eslint-disable-next-line no-restricted-globals
      console.group(label);
    }

    return this;
  }

  public groupEnd(): QpLoggerService {
    // eslint-disable-next-line no-restricted-globals
    console.groupEnd();

    return this;
  }

  public startTimer(label: Readonly<string>): QpLoggerService {
    // eslint-disable-next-line no-restricted-globals
    console.time(label);

    return this;
  }

  public stopTimer(label: Readonly<string>): QpLoggerService {
    // eslint-disable-next-line no-restricted-globals
    console.timeEnd(label);

    return this;
  }

  private _shouldLog(level: Readonly<QpLoggerLevelType>): boolean {
    return LEVELS[this.loggerLevel](level);
  }

  private _shouldBroadcast(level: Readonly<QpLoggerLevelType>): boolean {
    return LEVELS[this.httpLoggerLevel](level);
  }

  private _log(
    level: Readonly<QpLoggerLevelType>,
    method: Readonly<EQpLoggerConsoleMethod>,
    ...messages: QpLoggerMessagesType
  ): QpLoggerService {
    if (this._shouldLog(level)) {
      // eslint-disable-next-line no-restricted-globals
      console[method](...messages);

      LogRocket.captureMessage(messages.toString(), {
        tags: {
          method,
        },
      });
    }

    if (this._shouldBroadcast(level)) {
      // Parse with spaces the messages to have readable logs
      // The console do the same but internally FYI
      this._broadcast$.next({
        message: messages,
        method,
      });
    }

    return this;
  }

  private _init(): void {
    this.group('QpLoggerService initialized', true)
      ._log(
        BYPASS_LOGGER_LEVEL,
        EQpLoggerConsoleMethod.DEBUG,
        `Logger level: ${QpLoggerService._getHumanizedLoggerLevel(this.loggerLevel)}`
      )
      ._log(
        BYPASS_LOGGER_LEVEL,
        EQpLoggerConsoleMethod.DEBUG,
        `HTTP logger level: ${QpLoggerService._getHumanizedLoggerLevel(this.httpLoggerLevel)}`
      )
      .groupEnd();

    if (this._qpBroadcasterLoggerService) {
      this._startBroadcasting();
    }
  }

  private _startBroadcasting(): void {
    this._broadcast$
      .pipe(
        flatMap((loggerBroadcast: Readonly<IQpLoggerBroadcast>): Observable<unknown | never> => {
          return (
            this._qpBroadcasterLoggerService?.broadcast$(loggerBroadcast.message, loggerBroadcast.method).pipe(
              /*
               * This method subscribe be fault tolerant, this stream must never get closed
               * Example: if API return an error, it will close this stream
               */
              catchError((): Observable<unknown | never> => {
                return EMPTY;
              })
            ) ?? EMPTY
          );
        })
      )
      .subscribe();
  }
}
