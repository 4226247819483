// @ts-strict-ignore
import { QpAttributeDirective } from '@library/directives/qp-attribute/qp-attribute.directive';
import { QpAutocompleteValueType } from '@library/directives/qp-autocomplete/qp-autocomplete.models';
import { Directive, ElementRef, Input, Renderer2 } from '@angular/core';
import { QimaOptionalType } from '@qima/ngx-qima';

@Directive({
  selector: '[qpAutocomplete]',
  standalone: true,
})
export class QpAutocompleteDirective extends QpAttributeDirective<QimaOptionalType<QpAutocompleteValueType>> {
  public constructor(elementRef: ElementRef, renderer: Renderer2) {
    super(elementRef, renderer);

    this.qpAttributeKey = 'autocomplete';
  }

  @Input('qpAutocomplete')
  public set autocompleteValue(value: QimaOptionalType<QpAutocompleteValueType>) {
    this.qpAttributeValue = value;
    this.qpAttributeAdded = !!this.qpAttributeValue;
  }
}
