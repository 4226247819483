import { EQpLoggerConsoleMethod, QpLoggerMessagesType } from '@library/services/qp-logger/qp-logger.service.models';
import { Observable } from 'rxjs';

/**
 * @description
 * This class is used by library to let each angular project offer its own implementation
 */
export abstract class QpBroadcasterLoggerService implements IQpBroadcasterLoggerService<unknown> {
  public abstract broadcast$(message: QpLoggerMessagesType, method: EQpLoggerConsoleMethod): Observable<unknown>;
}

export interface IQpBroadcasterLoggerService<TResponse> {
  broadcast$(message: QpLoggerMessagesType, method: EQpLoggerConsoleMethod): Observable<TResponse>;
}
