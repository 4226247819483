import { EQpLoggerLevel, IQpLoggerConfig } from '@library/services/qp-logger/qp-logger.service.models';
import { InjectionToken } from '@angular/core';

export const QP_LOGGER_CONFIG = new InjectionToken<IQpLoggerConfig>('Logger config', {
  factory(): IQpLoggerConfig {
    return {
      loggerLevel: EQpLoggerLevel.DEBUG,
      httpLoggerLevel: EQpLoggerLevel.DISABLED,
    };
  },
});
