// @ts-strict-ignore
import { AfterViewInit, Directive, ElementRef, Input, Renderer2 } from '@angular/core';
import { QimaOptionalType } from '@qima/ngx-qima';
import { isEmpty, isNil } from 'lodash/index';

/**
 * @description
 * Allows the addition or deletion of an attribute programmatically.
 * We implement this directive who did pretty the same as [attr.**] but with the feature of
 * addition or deletion of an attribute, we don't want to have empty attribute like autocomplete or disabled.
 */
@Directive({
  selector: '[qpAttribute]',
})
export class QpAttributeDirective<TValue extends string> implements AfterViewInit {
  private _attributeAdded = true;
  private _attributeKey = '';
  private _attributeValue: QimaOptionalType<TValue>;

  @Input()
  public get qpAttributeKey(): string {
    return this._attributeKey;
  }

  public set qpAttributeKey(value: string) {
    this._attributeKey = value;

    this._apply();
  }

  @Input()
  public get qpAttributeValue(): TValue {
    return this._attributeValue;
  }

  public set qpAttributeValue(value: TValue) {
    this._attributeValue = value;

    this._apply();
  }

  @Input()
  public get qpAttributeAdded(): boolean {
    return this._attributeAdded;
  }

  public set qpAttributeAdded(isAdded: boolean) {
    this._attributeAdded = isAdded;

    this._apply();
  }

  public constructor(
    private readonly _elementRef: ElementRef,
    private readonly _renderer: Renderer2
  ) {}

  public ngAfterViewInit(): void {
    this._apply();
  }

  private _apply(): void {
    if (isEmpty(this.qpAttributeKey)) {
      return;
    }

    if (this.qpAttributeAdded && !isNil(this.qpAttributeValue)) {
      this._renderer.setAttribute(this._elementRef.nativeElement, this.qpAttributeKey, this.qpAttributeValue);
    } else {
      this._renderer.removeAttribute(this._elementRef.nativeElement, this.qpAttributeKey);
    }
  }
}
