/* eslint-disable no-magic-numbers */
export enum EQpLoggerLevel {
  DISABLED = 0,
  DEBUG = 1,
  INFO = 2,
  WARNING = 3,
  ERROR = 4,
}
/* eslint-enable no-magic-numbers */

export interface IQpLoggerConfig {
  /**
   * @description
   * Change the visibility of the logs
   */
  loggerLevel: EQpLoggerLevel;
  /**
   * @description
   * Change the broadcast of the logs into the API
   */
  httpLoggerLevel: EQpLoggerLevel;
}

export enum EQpLoggerConsoleMethod {
  DEBUG = 'debug',
  INFO = 'info',
  WARNING = 'warn',
  ERROR = 'error',
}

export interface IQpLoggerBroadcast {
  readonly message: QpLoggerMessagesType;
  readonly method: EQpLoggerConsoleMethod;
}

export const BYPASS_LOGGER_LEVEL = 777;

/**
 * @description
 * Internal usage only
 * Not exposed with {@link EQpLoggerLevel} on purpose!
 */
export type QpBypassLoggerLevelType = 777;

export type QpLoggerLevelType = EQpLoggerLevel | QpBypassLoggerLevelType;

export type QpLoggerMessagesType = unknown[];
